import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useRouter } from 'next/router';
import CartSuccessModal from '../Components/Element/CartSuccessModal';
import ErrorBoundry from '../Components/ErrorBoundry';
import '../public/assets/scss/app.scss';
import store from '../ReduxToolkit/store';
import CommonModel from '../Components/Element/CommonModel';
import SizeModal from '../Components/Element/SizeModal';
import YoutubeModal from '../Components/FashionDemo/YoutubeModal';
import DeleteModal from '../Components/Pages/UserDashboard/DeleteModal';
import ConfirmDeleteModal from '../Components/Pages/UserDashboard/ConfirmDeleteModal';
import CopyConfigModal from '../Layout/Common/Customizer/CopyConfigModal';
import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];

export default function MyApp({ Component, pageProps }) {
    const bootstarpRtl = '/assets/css/bootstrap.min.css';
    const router = useRouter();

    const pathArr = router.pathname.split('/');
    const titleName = pathArr[pathArr.length - (pathArr[pathArr.length - 1][0] == '[' ? 2 : 1)]
        .split('_')
        .map((data) => data.charAt(0).toUpperCase() + data.slice(1))
        .join(' ');
    const [domain, setDomain] = useState("");

    useEffect(() => {
        setDomain(window.location.origin);
    }, []);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDhBcYvcKqpiXO3jOcUX8erzH2491GOLjc",
        libraries,
    });

    return (
        <>
            <Head>
                <title>{titleName}</title>
                <link rel='icon' type='image/x-icon' href={`/assets/images/koalogo.png`} />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                <link id="rtl-link" rel="stylesheet" type="text/css" href={bootstarpRtl} />
                <link rel="canonical" href={`${domain}${router.asPath}`} />
                <link
                    href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                    rel="stylesheet" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
                    rel="stylesheet" />
                <meta name="google-site-verification" content="jsrLdK6AIfFp5ne1rmK0LzERfE0QJn7KIOxSVf671zE" />
            </Head>
            {/* Google Tag Manager Script */}
            <Script id="google-tag-manager" strategy="beforeInteractive">
                {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-PZSP4RW4');
                `}
            </Script>
            <Script
                src="https://www.googletagmanager.com/gtag/js?id=UA-58151529-1"
                strategy="afterInteractive"
            />
            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'UA-58151529-1');
                `}
            </Script>
            <Provider store={store}>
                <ErrorBoundry>
                    <Component {...pageProps} loadError={loadError} isLoaded={isLoaded} />
                    <CartSuccessModal />
                    <ToastContainer />
                    <SizeModal />
                    <CommonModel />
                    <DeleteModal />
                    <ConfirmDeleteModal />
                    <YoutubeModal />
                    <CopyConfigModal />
                </ErrorBoundry>
            </Provider>
        </>
    );
}
